import OrderbookPanel from "@/views/dashboard/component/OrderbookPanel";
import TradeListPanel from "@/views/dashboard/component/TradeListPanel";
import SymbolTickerList from "@/views/dashboard/component/SymbolTickerList";
import BreadCrumbs from "@/views/dashboard/component/BreadCrumbs";
import SelectorMulti from "@/views/dashboard/component/SelectorMulti";
import StrategyInfoPanel from "@/views/dashboard/component/StrategyInfoPanel";
import SelectedItemTable from "@/views/dashboard/component/SelectedItemTable";

import ConfirmDialog from "@/components/base/ConfirmDialog";

import {
  get_system_time,
  get_strategy_param,
  get_strategy_orders,
  update_strategy_param,
  get_account_list,
  get_strategy_status,
} from "@/api";

import { parse_depth, parse_symbolticker, containsAll, roundN, } from "@/utils";

import { updateExchangeInfoMixin, routerMixins, } from "@/mixins";

import { INITIAL_ORDER_TYPE, OVERLAY_TIMEOUT, } from "@/constants";

import * as _ from 'underscore';

export default {
  mixins: [routerMixins, updateExchangeInfoMixin],

  components: {
    OrderbookPanel,
    TradeListPanel,
    SymbolTickerList,
    SelectorMulti,
    ConfirmDialog,
    BreadCrumbs,
    StrategyInfoPanel,
    SelectedItemTable,
  },

  destroyed() {
    clearInterval(this.timer);
  },

  mounted: function () {

    this.loading = false;

    this.breadcrumbs_items = [
      {
        text: "Strategies",
        disabled: false,
        href: "/pages/strategies",
      },
      {
        text: this.strategy_name,
        disabled: false,
        href: `/pages/strategies/details/${this.strategy_name}`,
      },
    ]

    this.$nextTick(async () => {
      await this.initData();
    })

  },
  watch: {

    items(val) {
      this.params = this.genParamsFromItems(val);
      this.isOriginParams = this.params === this.originParams;
      this.paramsForPost = { 'items' : this.items }

      // console.log('params:', this.params)
      // console.log('originParams:', this.originParams)
      // console.log('watch:', this.isOriginParams)
    },

  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Event" : "Edit Event";
    },
  },
  data() {
    return {
      system_time: {},
      serverTime: "",
      localTime: "",
      firstLoad: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      dialogUpdate: false,
      editedItem: {
        symbol: "",
      },
      defaultItem: {
        symbol: "",
        autoCancel: true,
      },
      editedIndex: -1,

      paramsForPost: {},
      originParams: '',
      params: '',
      isOriginParams: true,

      strategy_name: 'FirstBloodStrategy',
      strategy_status: 'UNKNOWN',
      breadcrumbs_items: [],

      overlay: false,

      open_orders: [],
      historical_orders: [],
      positions: [],

      futuresSymbolList: [],
      futuresExchangeInfo: {},
      spotExchangeInfo: {},
      rules: [(value) => !!value || "Required."],
      orderType: INITIAL_ORDER_TYPE,
      timer: -1,
      orderTable: [],
      subscribedItems: [],
      longSelect: [],
      shortSelect: [],
      symboltickers: [],
      symbolDefList: [],
      symbols: [],
      abdatas: {},
      hasAbDatas: {},
      tickerList: [],
      tickerDatas: {},
      upperColor: "#56f755",
      lowerColor: "#ff0000",
      obSize: ["50%", "50%"],
      tradeSize: ["30%", "30%", "40%"],
      depth: "wait for spot depth data!",
      futuresDepth: "wait for futuresDepth data!",
      symbolTicker: "wait for symbol ticker data!",
      futuresSymbolTicker: "wait for futuresSymbolTicker data!",
      orderBookTicker: "wait for orderbook ticker data",
      futuresOrderBookTicker: "wait for futuresOrderBookTicker data",
      accounts: {},
      futures_total_positions: {},

      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Symbol", value: "symbol", sortable: true },
        { text: "Enabled", value: "enabled", sortable: false },
        { text: "Auto Cancel", value: "autoCancel", sortable: false },
        { text: "TriggerTime", value: "triggerTime", sortable: true },
        { text: "Price", value: "price", sortable: true },
        { text: "Quantity", value: "qty", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
    };
  },

  methods: {

    genParamsFromItems: function (items) {
      if (items !== undefined && items !== null) {
        const data = {};
        items.forEach((item, index, arr) => {
          const fValue = parseFloat(item.base);
          if (fValue > 0) {
            data[item.symbol] = fValue;
          }
        });
        return JSON.stringify({ update_balances: data }, null, 4);
      }
      return "";
    },

    async initData() {

      get_strategy_param(this.strategy_name)
        .then((strategy_param) => {

          const params = strategy_param.params.items;
          let id = 0;
          this.items = Object.keys(params).map((key) => {
            const item = params[key]
            item.id = id++
            return item
          })

          this.originParams = this.genParamsFromItems(this.items)

        })
        .catch((reason) => {
          console.error(reason);
        });
        
        this.timer = setInterval(() => {

          get_system_time("SPOT").then((({ system_time, serverTime, localTime, }) => {
            this.system_time = system_time;
            this.serverTime = serverTime;
            this.localTime = localTime;
          }))

          get_strategy_orders(this.strategy_name).then(({ open_orders, historical_orders, }) => {
              this.open_orders = open_orders;
              this.historical_orders = historical_orders;
              // console.log(historical_orders)
          }).catch((reason) => {
              console.error(reason);
              // this.logout()
          })
        }, 3000)

    },

    getShowData(symbol, accountType, ab) {
      const key = `${symbol}_${accountType}_${ab}`;
      if (this.abdatas === {} || !this.abdatas.hasOwnProperty(key)) {
        return [{}, {}, {}, {}, {}];
      }
      return this.abdatas[key];
    },
    getPrice(symbol, orderType, direction) {

      if (orderType === 'MARKET') {
        return 0
      }

      const longPriceLevel = direction === 'LONG' ? this.longPriceLevel : this.shortPriceLevel;

      const isAsk = longPriceLevel.substring(0, 1) === 'a';

      const key_suffix = isAsk ? 'asks' : 'bids';

      const level = parseInt(longPriceLevel.substring(1, 2))

      const key = `${symbol}_USDT_FUTURE_${key_suffix}`

      const priceItem = isAsk ? this.abdatas[key][this.abdatas[key].length - level] : this.abdatas[key][level - 1]

      return priceItem.price

    },

    onUpdate: function () {
      this.closeUpdate()

      update_strategy_param({
        strategyName: this.strategy_name, params: this.paramsForPost,
        updateType: "PARAMS",
      }).then((strategy_param) => {
        console.log("result:", strategy_param)
      })
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    genParamsFromItems: function (items) {
      return JSON.stringify(items, null, 4);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.items.push({});
        this.items.pop();
      } else {
        this.items.push(this.editedItem);
      }

      this.close();
    },

    tryToUpdate() {
      this.dialogUpdate = true;
    },

    closeUpdate() {
      this.dialogUpdate = false;
    },
  },
};
